import { useMediaQuery } from '@kaliber/use-media-query'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'
import { HeadingLg } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { TeamSlider } from '/features/buildingBlocks/TeamSlider'
import { viewportMd } from '/cssGlobal/media.css'
import { ButtonLinkBlue } from '/features/buildingBlocks/Button'
import { ScrollAnimation } from '/features/buildingBlocks/ScrollAnimation'

import styles from './MeetTheTeam.css'

export function MeetTheTeam({ items, title, subtitle = undefined, description, button }) {
  return (
    <MeetTheTeamBase
      className={styles.component}
      {...{ title, description, items, subtitle, button }}
    />
  )
}

export function MeetTheTeamInContent({ items, title, subtitle = undefined, description, button }) {
  return (
    <MeetTheTeamBase
      className={styles.componentInContent}
      {...{ title, description, items, subtitle, button }}
    />
  )
}

function MeetTheTeamBase({ className, items, title, subtitle = undefined, description, button }) {
  const viewportIsMd = useMediaQuery(viewportMd)

  return (
    <div {...{ className }}>
      <ScrollAnimation
        layoutClassName={styles.rightShape}
        width={666}
        height={581}
        path="M0.655754 580.625L342.071 0.375H665.343L322.416 580.625H0.655754Z"
      />
      {viewportIsMd
        ? <TeamGrid {...{ title, description, items, subtitle, button }} layoutClassName={styles.grid}  />
        : <TeamSlider {...{ title, description, button, subtitle }} cards={items} layoutClassName={styles.teamSlider} />
      }
    </div>
  )
}

function TeamGrid({ title, description, subtitle, items, layoutClassName, button }) {
  return (
    <div className={cx(styles.componentTeamGrid, layoutClassName)}>
      {(title || description) && (
        <div className={styles.content}>
          {title && <HeadingLg h={2} {...{ title }} />}
          <div className={styles.buttonAndDescription}>
            {button?.label && button?.ref && (
              <ButtonLinkBlue
                href={determineDocumentPathSync({ document: button.ref, routeMap })}
                dataX='link'
                layoutClassName={styles.button}
              >
                {button.label}
              </ButtonLinkBlue>
            )}
            <div className={styles.subtitleAndDescription}>
              {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
              {description && <p className={styles.description}>{description}</p>}
            </div>
          </div>
        </div>
      )}

      {items && items.map((item, index) => (
        <figure className={cx(styles.member, styles[`member${index + 1}`])} key={index}>
          {item.asset && <ImageCover aspectRatio={9 / 16} image={item} layoutClassName={styles.image} />}
          {(item.number || item.description) && <div className={styles.backdrop} />}
          <figcaption className={styles.factContent}>
            <div className={styles.factNumber}>{item.number}</div>
            <div className={styles.factTitle}>{item.description}</div>
          </figcaption>
        </figure>
      ))}
    </div>
  )
}
