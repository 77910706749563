import { useKeenSlider } from 'keen-slider/react'
import { useElementSize } from '@kaliber/use-element-size'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { ImageCover } from '/features/buildingBlocks/Image'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'
import { ButtonLinkBlue } from './Button'
import { HeadingLg } from './Heading'

import styles from './TeamSlider.css'

export function TeamSlider({ cards, layoutClassName, title, button, description, subtitle }) {
  const { ref: sizeRef, size: { width } } = useElementSize()
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const slides = {
    perView: 1.2,
    spacing: 12
  }

  const [carouselRef] = useKeenSlider({
    slides,
    selector: x => x.childNodes,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    dragStarted(s) {
      pushToDataLayer({
        event: 'interaction_start',
        metadata: {
          interaction: {
            title: 'team-slider',
            type: 'slided',
            index: s.track.details.rel
          }
        }
      })
    },
    dragEnded(s) {
      pushToDataLayer({
        event: 'interaction_stop',
        metadata: {
          interaction: {
            title: 'team-slider',
            type: 'slided',
            index: s.track.details.rel
          }
        }
      })
    }
  })

  const cardsAmount = cards ? cards.length - 1 : 0

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Content {...{ title, description, button, subtitle }} />
      <div ref={sizeRef}>
        {width && (
          <div className={styles.layout}>
            <ol
              className={cx(styles.carousel, !width && styles.hidden)}
              ref={carouselRef}
            >
              {cards && cards.map(card => card.asset && (
                <li key={card._key} className={styles.cardListItem}>
                  <Card
                    title={card.number}
                    text={card.description}
                    image={card}
                    layoutClassName={cx(styles.card, (card.number || card.description) && styles.hasBackdrop)}
                  />
                </li>
              ))}
            </ol>
            <progress className={styles.progressBar} value={currentSlide} max={cardsAmount} />
          </div>
        )}
      </div>
    </div>
  )
}

function Card({ text, title, image, layoutClassName }) {
  return (
    <div className={cx(styles.componentCard, layoutClassName)}>
      <ImageCover aspectRatio={9 / 16} {...{ image }} layoutClassName={styles.cardImage} />
      <h3 className={styles.cardTitle}>
        {title}
      </h3>
      <p className={styles.description}>
        {text}
      </p>
    </div>
  )
}

function Content({ title, description, button, subtitle }) {
  return (
    <div className={styles.componentContent}>
      {title && <HeadingLg h={2} {...{ title }} />}
      <div className={styles.buttonAndDescription}>
        <div className={styles.description}>
          {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
          {description && <p>{description}</p>}
        </div>
        {button?.label && button?.ref && (
          <ButtonLinkBlue
            href={determineDocumentPathSync({ document: button.ref, routeMap })}
            dataX='link'
            layoutClassName={styles.button}
          >
            {button.label}
          </ButtonLinkBlue>
        )}
      </div>
    </div>
  )
}
